<template>
  <div
    class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div class="flex flex-column align-items-center justify-content-center">
      <div
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            20deg,
            rgba(150, 150, 150, 0.1) 10%,
            rgba(150, 150, 150, 0.4) 30%
          );
        "
      >
        <div
          class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center"
          style="border-radius: 53px"
        >
          <!-- <img
              src="../../../public/assets/layout/images/sespa/logo_sisfreq.png"
              alt="sisfreq logo"
              class="mt-2"
              height="120"
            /> -->
          <div class="number">404</div>
          <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">
            PÁGINA NÃO ENCONTRADA
          </h1>
          <div class="text-600 mb-5">
            ESTA PÁGINA NÃO FOI ENCONTRADA. VERIFIQUE A URL!
          </div>
          <router-link to="/system">
            <Button
              class="p-button-secondary"
              icon="pi pi-arrow-circle-left"
              label="Voltar"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <style scoped>
.number {
  font-size: 240px;
  color: #707375;
  padding-top: 10px;
  position: relative;
  z-index: 9;
  line-height: 100%;
}
</style>
  